import client from "./client";

export default {
  createSetpointPreset: (id, data) =>
    client.post(`actuators/${id}/setpoint_presets`, data),
  getAllByVenue: (venueId) => client.get(`venues/${venueId}/actuators`),
  get: (id) => client.get(`actuators/${id}`),
  update: (id, data) => client.patch(`actuators/${id}`, data),
  getLogs: (id) => client.get(`actuators/${id}/logs`),
  getAllSetpointPresets: () => client.get(`setpoint_presets`),
  getAlerts: (id) => client.get(`actuators/${id}/alerts`),
  getAlertsByVenue: (venueId) =>
    client.get(`venues/${venueId}/actuators/alerts`),
  markAlertAsReal: (id, data) =>
    client.patch(`actuators/${id}/mark_alert`, data),
  createSchedule: (data, scheduleType) =>
    client.post(`schedules?scheduleType=${scheduleType}`, data),
  updateSchedule: (scheduleType, id, data) =>
    client.patch(`schedules/${id}?scheduleType=${scheduleType}`, data),

  deleteSchedule: (scheduleType, id) =>
    client.delete(`schedules/${id}?scheduleType=${scheduleType}`),
  deleteAlert: (id, alerts) =>
    client.delete(`actuators/${id}/alerts/delete/`, { data: { alerts } }),
  activateMaintenanceMode: (id, data) =>
    client.patch(`actuators/${id}/disable_automatic_control`, data),
  emergencyShutdown: (venueId) =>
    client.patch(`venues/${venueId}/actuators/emergency_shutdown`),
  runVenueActuatorsControlCycle: (venueId) =>
    client.post(`venues/${venueId}/actuators/run_control_cycle`),
};
